import { createSlice } from '@reduxjs/toolkit';
import { getForm, updateForm, addForm, deleteForm, getFormList, getEditForm, importForm, exportForm, importFormSample, getHospitals, getDefaultForms, accessForm } from '../form';

const initialState = {
	formList: [],
	formData: [],
	formErrorMessage: '',
	Loading: false,

	isFormUpdateSuccess: false,
	updateFormErrorMessage: '',

	isFormAddedSuccess: false,
	addFormErrorMessage: '',

	isFormDeletedSuccess: false,
	isFormDeleteErrorMessage: '',

	formListAll: [],
	isFetchformListAllSuccess: false,
	formListErrorMessage: '',

	editFormData: [],
	isFetchEditFormSuccess: false,
	editFormErrorMsg: '',

	isFormImportedSuccess: false,
	importFormErrorMessage: '',

	isFormImportSampleSuccess: false,
	importSampleFormErrorMessage: '',
	importSampleFormPath: '',

	isFormExportedSuccess: false,
	exportFormErrorMessage: '',
	exportFormPath: '',

	hospitalList: [],
	hospitalListErrorMessage : '',

	isFormAccessChangeSuccess: false,
	formAccessErrorMessage : ''

};

const formSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		exportFormComplete: (state) => {
			state.isFormExportedSuccess = false;
		},
		importSampleFormComplete: (state) => {
			state.isFormImportSampleSuccess = false;
		},
	},
	extraReducers: {
		[getForm.pending]: (state) => {
			state.Loading = true;
		},
		[getForm.fulfilled]: (state, action) => {
			state.Loading = false;
			state.isFormUpdateSuccess = false;
			state.isFormDeletedSuccess = false;
			state.isFormAddedSuccess = false;
			state.isFetchEditFormSuccess = false;
			state.isFormImportedSuccess = false;
			state.isFormExportedSuccess = false;
			state.isFormAccessChangeSuccess = false;
			state.formData = action.payload?.response.data.data;
			state.formList = action.payload?.response.data.data.data;
		},
		[getForm.rejected]: (state, action) => {
			state.Loading = false;
			state.formErrorMessage = action?.payload?.message;
		},

		[getDefaultForms.pending]: (state) => {
			state.Loading = true;
		},
		[getDefaultForms.fulfilled]: (state, action) => {
			state.Loading = false;
			state.isFormUpdateSuccess = false;
			state.isFormDeletedSuccess = false;
			state.isFormAddedSuccess = false;
			state.isFetchEditFormSuccess = false;
			state.isFormImportedSuccess = false;
			state.isFormExportedSuccess = false;
			state.isFormAccessChangeSuccess = false;
			state.formData = action.payload?.response.data.data;
			state.formList = action.payload?.response.data.data.data;
		},
		[getDefaultForms.rejected]: (state, action) => {
			state.Loading = false;
			state.formErrorMessage = action?.payload?.message;
		},

		[updateForm.pending]: (state) => {
			state.Loading = true;
		},
		[updateForm.fulfilled]: (state) => {
			state.Loading = false;
			state.isFormUpdateSuccess = true;
		},
		[updateForm.rejected]: (state, action) => {
			state.Loading = false;
			state.updateFormErrorMessage = action?.payload?.message;
		},

		[addForm.pending]: (state) => {
			state.Loading = true;
		},
		[addForm.fulfilled]: (state) => {
			state.Loading = false;
			state.isFormAddedSuccess = true;
		},
		[addForm.rejected]: (state, err) => {
			state.Loading = false;
			state.addFormErrorMessage = err;
		},

		[deleteForm.fulfilled]: (state, { payload }) => {
			state.isFormDeletedSuccess = true;
			state.res = payload.response;
		},
		[deleteForm.rejected]: (state, err) => {
			state.isFormDeleteErrorMessage = err;
		},
		[getFormList.fulfilled]: (state, action) => {
			state.formListAll = action.payload && action.payload.response.data.data;
			state.isFetchformListAllSuccess = true;
		},
		[getFormList.rejected]: (state, action) => {
			state.Loading = false;
			state.formListErrorMessage = action?.payload?.message;
		},
		[getEditForm.pending]: (state) => {
			state.Loading = true;
		},
		[getEditForm.fulfilled]: (state, action) => {
			state.Loading = false;
			state.isFetchEditFormSuccess = true;
			state.editFormData = action.payload && action.payload.response.data.data;
		},
		[getEditForm.rejected]: (state, action) => {
			state.Loading = false;
			state.isFetchEditFormSuccess = false;
			state.editFormErrorMsg = action?.payload?.message;
		},
		[importForm.fulfilled]: (state) => {
			state.isFormImportedSuccess = true;
		},
		[importForm.rejected]: (state, action) => {
			state.importFormErrorMessage = action?.payload?.message;
		},

		[exportForm.fulfilled]: (state, action) => {
			state.isFormExportedSuccess = true;
			state.exportFormPath = action.payload && action.payload.response.data.data.file_path;
		},
		[exportForm.rejected]: (state, action) => {
			state.isFormExportedSuccess = false;
			state.exportFormErrorMessage = action?.payload?.message;
		},

		[importFormSample.fulfilled]: (state, action) => {
			state.isFormImportSampleSuccess = true;
			state.importSampleFormPath = action.payload && action.payload.response.data.data.file_path;
		},
		[importFormSample.rejected]: (state, action) => {
			state.isFormImportSampleSuccess = false;
			state.importSampleFormErrorMessage = action?.payload?.message;
		},

		[getHospitals.fulfilled]: (state, action) => {
			state.hospitalList = action.payload?.response.data.data;
		},
		[getHospitals.rejected]: (state, action) => {
			state.hospitalListErrorMessage = action?.payload?.message;
		},
		[accessForm.fulfilled]: (state) => {
			state.isFormAccessChangeSuccess = true;
		},
		[accessForm.rejected]: (state, err) => {
			state.isFormAccessChangeSuccess = false;
			state.formAccessErrorMessage = err?.payload?.message;
		},
	},
});

export const { exportFormComplete, importSampleFormComplete } = formSlice.actions;
export default formSlice.reducer;