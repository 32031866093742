import React from 'react';
import findIndex from 'lodash/findIndex';
import Button from '../Button';
import { Container, Body, TD, TH, Head, TR, Wrapper, A } from './styled';
const Table = ({ tableHeading, tableFields, tableData, tableActions, tableFormatFields }) => {
	return (
		<Wrapper>
			<Container>
				<Head>
					<TR>
						{tableHeading.map((item, index) => {
							return (
								<TH key={index}>{item}</TH>
							);
						})}
					</TR>
				</Head>
				<Body>
					{tableData && tableData.map((item, index) => {
						return (
							<TR key={index}>
								{tableFields && tableFields.map((field, inx) => {
									let findindex = findIndex(tableFormatFields, ['name', field]);
									if (findindex === -1) {
										return (
											<TD key={index + '_' + inx}>
												{item[field] !== '' ? item[field] : null}
											</TD>
										);
									} else {
										return (
											<TD key={index + '_' + inx}>
												<A onClick={() => tableFormatFields[findindex].handleClick(item)}>{tableFormatFields[findindex].text(item)}</A>
											</TD>
										);
									}
								})
								}
								{tableActions && tableActions.length ?
									<TD className='flex'>
										{tableActions &&
											tableActions.map((action) => {
												if (action.name != 'access' && action.name != 'access2' && action.name != 'default' && action.name != 'mark_paid' && action.name != 'billing_preview')
													return (
														<Button title={action.title} key={action.name} onClick={() => action.handleClick(item)} size={action.size} variant={action.variant} variantCondition={() => action.variantCondition(item)}>
															{action.text}
														</Button>
													);
												else if (action.name == 'access2' && item.access)
													return (
														<Button title={action.title} key={action.name} onClick={() => action.handleClick(item)} size={action.size} variant={action.variant} variantCondition={() => action.variantCondition(item)}>
															{action.text}
														</Button>
													);
												else if (action.name == 'access' && !item.access) {
													return (
														<Button title={action.title} key={action.name} onClick={() => action.handleClick(item)} size={action.size} variant={action.variant} variantCondition={() => action.variantCondition(item)}>
															{action.text}
														</Button>
													);
												} else if (action.name == 'default' && item.default === 'No') {
													return (
														<Button title={action.title} key={action.name} onClick={() => action.handleClick(item)} size={action.size} variant={action.variant} variantCondition={() => action.variantCondition(item)}>
															{action.text}
														</Button>
													);
												} else if (action.name == 'mark_paid' && item.payment_status === 'Unpaid') {
													return (
														<Button title={action.title} key={action.name} onClick={() => action.handleClick(item)} size={action.size} variant={action.variant} variantCondition={() => action.variantCondition(item)}>
															{action.text}
														</Button>
													);
												} else if (action.name == 'billing_preview' && item.payment_status === 'Paid') {
													return (
														<Button title={action.title} key={action.name} onClick={() => action.handleClick(item)} size={action.size} variant={action.variant} variantCondition={() => action.variantCondition(item)}>
															{action.text}
														</Button>
													);
												} else {
													return (
														<React.Fragment key={action.name}></React.Fragment>
													);
												}
											})
										}
									</TD> : null
								}
							</TR>
						);
					}
					)}
					{tableData && tableData.length === 0 ? <TR>
						{tableData && tableData.length < 1 ? <TD className='message' colSpan={tableHeading.length}>
							No Data Available
						</TD> : null}
					</TR> : null}
				</Body>
			</Container>
		</Wrapper>
	);
};

export default Table;