import React from 'react';
import { saveAs } from 'file-saver';

export const AuthContext = React.createContext(null);

export const useAuth = () => React.useContext(AuthContext);

export const slugify = (str) => {
	return str
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9]/gi, '');
};

export const downloadFile = (filePath) => {
	const fileName = filePath.split('/').pop();
	return saveAs(filePath, fileName);
};