import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { BiSortDown } from 'react-icons/bi';
import { TbLock } from 'react-icons/tb';
import { IoMdShareAlt } from 'react-icons/io';
import { ImCopy } from 'react-icons/im';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import useDebounce from '../../utils/useDebounceHook';
import { getDefaultForms, deleteForm, exportForm, getHospitals, accessForm } from '../../redux/form';
import { exportFormComplete } from '../../redux/form/slice';
import {
	Buttons,
	Footer,
	TableContent,
	Header,
	Modal,
	Overlay,
	Container,
	Content,
	SearchInput,
	InputContainer,
} from './styled';
import { downloadFile } from '../../utils/helper';

const FormList = () => {
	const TableHeadings = ['ID', 'Form Name', 'Access', ''];
	const TableFields = ['id', 'form_name', 'status'];
	const TableActions = [
		{
			'name': 'preview',
			'handleClick': (item) => handlePreview(item.id),
			'size': 'xs',
			'variant': 'warning',
			'title': 'Preview',
			'text': <AiOutlineEye />
		},
		{
			'name': 'sorting',
			'handleClick': (item) => handleSorting(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Sorting',
			'text': <BiSortDown />
		},
		{
			'name': 'access',
			'handleClick': (item) => handleAccess(item.id, (item.access ? 0 : 1)),
			'size': 'xs',
			'variant': 'success',
			'title': 'Status - Unlock',
			'visible' : (item) => (item.access ? false : true),
			'text': <TbLock />,
		},
		{
			'name': 'access2',
			'handleClick': (item) => handleAccess(item.id, (item.access ? 0 : 1)),
			'size': 'xs',
			'variant': 'danger',
			'visible' : (item) => (item.access ? false : true),
			'title': 'Status - Lock',
			'text': <TbLock />,
		},
		{
			'name': 'edit',
			'handleClick': (item) => handleEdit(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Edit',
			'text': <AiOutlineEdit />
		},
		{
			'name': 'export',
			'handleClick': (item) => handleExport(item.id),
			'size': 'xs',
			'variant': 'success',
			'title': 'Export',
			'text': <IoMdShareAlt />
		},
		{
			'name': 'clone',
			'handleClick': (item) => handleClone(item.id),
			'size': 'xs',
			'variant': 'primary',
			'title': 'Clone Form',
			'text': <ImCopy />
		},
		{
			'name': 'delete',
			'handleClick': (item) => handleDelete(item.id),
			'size': 'xs',
			'variant': 'danger',
			'title': 'Delete',
			'text': <AiOutlineDelete />
		}
	];
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { formList, isFormDeletedSuccess, formData, Loading, isFormExportedSuccess, exportFormPath, /* hospitalList, */ isFormAccessChangeSuccess } = useSelector(state => state.form);
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [searchUserName, setSearchUserName] = useState('');
	const [deleteMsg, setDeleteMsg] = useState({ show: false, id: null });
	const debouncedSearchTerm = useDebounce(searchUserName, 500);
	const [page, setPage] = useState(1);

	useEffect(() => {
		dispatch(getHospitals());
	}, []);

	useEffect(() => {
		if (formList.length){
			let newFormList = formList.map((element) => {
				let status = (element.access)? 'Active': 'Inactive';
				return ({ ...element, status: status });
			});
			setCurrentItems(newFormList);
			formList.length ? setPageCount(formData.last_page) : setPageCount(0);
		}
		else {
			setCurrentItems([]);
			setPageCount(0);
		}
	}, [formList]);

	useEffect(() => {
		if (debouncedSearchTerm == '') {
			dispatch(getDefaultForms({ page: page }));
		}
		else if (debouncedSearchTerm !== '') {
			dispatch(getDefaultForms({ page: page, search: debouncedSearchTerm }));
		}
		else {
			dispatch(getDefaultForms({ page: page }));
		}
	}, [page, debouncedSearchTerm]);

	useEffect(() => {
		if (isFormDeletedSuccess) {
			dispatch(getDefaultForms({ page: page }));
		}
	}, [isFormDeletedSuccess]);

	useEffect(() => {
		if (isFormExportedSuccess) {
			dispatch(exportFormComplete());
			downloadFile(exportFormPath);
		}
	}, [isFormExportedSuccess]);


	useEffect(() => {
		if (isFormAccessChangeSuccess) {
			dispatch(getDefaultForms({ page: page }));
		}
	}, [isFormAccessChangeSuccess]);
	const handlePreview = (id) => {
		navigate(`/form-management/previewform/${id}/${page}`);
	};
	const handleSorting = (id) => {
		navigate(`/form-management/sortingform/${id}/${page}`);
	};
	const handleEdit = (id) => {
		navigate(`/form-management/editform/${id}/${page}`);
	};
	const handleClone = (id) => {
		navigate(`/form-management/create?clone_form=${id}`);
	};
	const handleExport = (id) => {
		dispatch(exportForm({ 'form_ids[0]' : id }));
	};
	const handleAccess = (id, newAccess) => {
		const body = { form_id: id, access: newAccess };
		dispatch(accessForm({ body }));
	};
	const handleDelete = (id) => {
		setDeleteMsg({
			show: true,
			id,
		});
	};

	const hendleDeleteYes = () => {
		if (deleteMsg.show && deleteMsg.id) {
			const body = { form_id: deleteMsg.id };
			dispatch(deleteForm({ body }));
			setDeleteMsg({
				show: false,
				id: null,
			});
		}
	};

	const handlePageClick = (event) => {
		const pageNum = event.selected + 1;
		setPage(pageNum);
	};

	const handleExportForm = () => {
		dispatch(exportForm());
	};

	const handleSearchByName = (event) => {
		const inputVal = event.target.value;
		setSearchUserName(inputVal);
		setPage(1);
	};
	return (
		<Container>
			<Content>
				<Header>
					<Typography
						variant="h4"
						color="black"
						weight="500"
					>
						{t('FORMLIST.HEADING')}
					</Typography>
				</Header>
				<SearchInput>
					<InputContainer>
						<Input placeholder={t('FORMLIST.PLACEHOLDER.INPUT')} onChange={handleSearchByName} value={searchUserName} />
					</InputContainer>
					<Button title={t('FORMLIST.BTNEXPORT')} onClick={handleExportForm} size={'sm'}>
						{t('FORMLIST.BTNEXPORT')}
					</Button>
				</SearchInput>
				<TableContent>
					{Loading ? <Loader /> : <Table tableHeading={TableHeadings} tableFields={TableFields} tableData={currentItems} tableActions={TableActions} />}
					<Footer>
						<ReactPaginate
							breakLabel="..."
							nextLabel={<HiChevronDoubleRight />}
							onPageChange={handlePageClick}
							pageRangeDisplayed={5}
							pageCount={pageCount}
							previousLabel={<HiChevronDoubleLeft />}
							forcePage={pageCount === 0 ? -1 : page - 1}
						/>
					</Footer>
				</TableContent>
			</Content>
			{deleteMsg.show ?
				<Overlay>
					<Modal>
						<Typography
							variant="h4"
							color="black"
						>
							{t('FORMLIST.DELETECONFIRM')}
						</Typography>
						<Buttons>
							<Button title={t('ADDDOCTOR.BTNTEXT')} onClick={() => setDeleteMsg(false)} size={'sm'}>{t('FORMLIST.BTNNO')}</Button>
							<Button title={t('ADDDOCTOR.BTNTEXT')} onClick={hendleDeleteYes} size={'sm'} variant={'danger'}>{t('FORMLIST.BTNYES')}</Button>
						</Buttons>
					</Modal>
				</Overlay>
				: null}
		</Container>
	);
};

export default FormList;